<template>
    <div>
        <y-page-head
            :title="title"
            icon="md-comment-outline"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    tag="router-link"
                    :to="{ name: 'commenting-list' }"
                >
                    {{ $t('button.back') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <div class="row">
                    <div class="col-sm-8">
                        <y-single-comment
                            v-if="comment"
                            :comment="comment"
                            :metadata="metadata"
                            :depth="0"
                            @done="reFetch"
                        />

                        <y-single-reply
                            :metadata="metadata"
                            @done="reFetch"
                        />
                    </div>

                    <div class="col-sm-4">
                        <y-single-info
                            :info="comment"
                            :metadata="metadata"
                            @done="reFetch"
                        />

                        <y-single-files :comment="comment" />
                    </div>
                </div>
            </main>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';

    import YSingleComment from '@/modules/commenting/components/single/Comments';
    import YSingleReply from '@/modules/commenting/components/single/Reply';
    import YSingleInfo from '@/modules/commenting/components/single/Info';
    import YSingleFiles from '@/modules/commenting/components/single/Files';

    export default {
        name: 'CommentingSingle',

        components: {
            YSingleComment,
            YSingleReply,
            YSingleInfo,
            YSingleFiles,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('commenting-list', this.$t('breadcrumbs.commenting')),
                this.$bc(this.$t('breadcrumbs.comment_single')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title ? this.title : this.$t('comment.single.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                comment : [],
                metadata: [],
            };
        },

        computed: {
            /**
             * Title of the comment
             */
            title() {
                return this.comment && this.comment.title ? this.comment.title : this.$t('comment.single.title');
            },
        },

        methods: {
            /**
             * Fetch the comment
             */
            fetch() {
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Commenting.single(params).then((response) => {
                    this.metadata = response.data.metadata;
                    this.comment = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };

</script>
