<template>
    <y-panel
        class="comment__files"
        :title="$t('comment.single.files.title')"
    >
        <div v-if="comment.files && comment.files.length">
            <div class="row">
                <div
                    v-for="file in comment.files"
                    :key="file.id"
                    class="col col-sm-4 comment__files__file"
                >
                    <a
                        :href="file.link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            v-if="file.versions.panel_thumb"
                            :src="file.versions.panel_thumb"
                            :alt="comment.post.title"
                        >
                        <y-form-icon
                            v-else-if="file.extension === 'pdf'"
                            icon="md-file-pdf"
                        />
                        <y-form-icon
                            v-else
                            icon="md-file-document-outline"
                        />
                    </a>
                </div>
            </div>
        </div>

        <y-empty-state
            v-else
            class="mb0"
            :message="$t('comment.single.files.no_files')"
        />
    </y-panel>
</template>

<script>

    import { YFormIcon } from '@deps';
    import { YEmptyState } from '@deps/EmptyState';
    
    export default {
        name: 'SingleFiles',

        components: {
            YFormIcon,
            YEmptyState,
        },

        props: {
            comment: [Object, Array],
        },
    };

</script>
