<template>
    <div
        v-if="comment"
        class="comment__msg"
    >
        <div
            class="comment-item"
            :class="{'comment-item-reply': isReply, 'private__comment': comment.is_private}"
        >
            <div
                v-if="isReply"
                class="comment-item-header"
            >
                <!-- name -->
                <span
                    v-if="comment.user.name"
                    class="comment-item-name"
                >
                    {{ comment.user.name }}
                    <span
                        v-if="comment.is_private"
                        class="label private__label"
                    >
                        {{ $t('comment.single.info.private') }}
                    </span>
                </span>

                <!-- status -->
                <span
                    v-if="!showCombo && comment.flag_title"
                    class="label comment-item-change-status"
                    @click="comboStatus"
                >
                    <i class="icon md-pencil-box-outline" />
                    {{ comment.flag_title }}
                </span>
                <div
                    v-if="showCombo && metadata && metadata.applicable_flags"
                    class="comment-item-change-status-combo"
                >
                    <y-form-field
                        v-model="model"
                        type="select"
                        class="mb0"
                        name="status"
                        no-label
                        :options="metadata.applicable_flags"
                    />
                    <y-button
                        class="form-btn green"
                        @click="changeStatus"
                    >
                        {{ $t('comment.single.reply.confirmed') }}
                    </y-button>
                </div>
            </div>

            <!-- text -->
            <div
                v-if="comment.text"
                class="comment-item-text"
                v-html="comment.text"
            />

            <div
                v-if="isReply"
                class="d-f justify-content-between align-items-center comment-item-footer"
            >
                <span
                    v-if="isReply && comment.created_at"
                    class="comment-item-date"
                >
                    {{ comment.created_at | date('YYYY/M/D - HH:mm', $i18n.locale) | digits }}
                </span>

                <template v-if="depth < metadata.commenting_nesting_layers">
                    <div
                        v-if="isReply && !showReply"
                        class="comment-item-reply-button"
                        @click="showReply = !showReply"
                    >
                        <span><i class="d-b icon md-reply" /></span>
                        <span class="reply">
                            {{ $t('comment.single.reply.title') }}
                        </span>
                    </div>
                    <div
                        v-if="isReply && showReply"
                        class="comment-item-reply-button"
                        @click="showReply = !showReply"
                    >
                        <span class="reply">
                            {{ $t('comment.single.reply.close') }}
                        </span>
                    </div>
                </template>
            </div>
        </div>

        <!-- Reply Form -->
        <y-single-reply
            v-if="isReply && showReply"
            :id="comment.id"
            class="reply-to-reply"
            :metadata="metadata"
            @done="$emit('done')"
        />

        <!-- Reply -->
        <div
            v-if="depth < metadata.commenting_nesting_layers"
            class="comment-item-reply-in-reply"
        >
            <template v-for="reply in comment.replies">
                <single-comment
                    :key="reply.id"
                    :comment="reply"
                    :metadata="metadata"
                    :depth="depth + 1"
                    is-reply
                    @done="$emit('done')"
                />
            </template>
        </div>
    </div>
</template>

<script>

    import { YFormField } from '@deps';
    import YSingleReply from '@/modules/commenting/components/single/Reply';

    export default {
        name: 'SingleComment',

        components: {
            YFormField,
            YSingleReply,
        },

        props: {
            comment : [Object, Array],
            metadata: [Object, Array],
            depth   : Number,
            isReply : {
                type   : Boolean,
                default: false,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : null,
                showReply: false,
                showCombo: false,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.comment) {
                this.$set(this, 'model', this.comment.flag);
            }
        },

        methods: {
            /**
             * Show status combo
             */
            comboStatus() {
                this.showCombo = !this.showCombo;
            },
            /**
             * Change comment status
             */
            changeStatus() {
                let status = null;
                if (this.model === this.comment.flag) {
                    this.showCombo = !this.showCombo;
                }
                const params = {
                    id  : this.comment.id,
                    flag: this.model,
                };
                if (this.model !== this.comment.flag) {
                    status = this.$services.Commenting.replyByAdmin(params).then(() => {
                        this.$toast.success(this.$t('comment.single.reply.change_status.success_msg'));
                        this.$emit('done');
                    }).catch((error) => {
                        this.handleError(error, this.$t('comment.single.reply.change_status.error_msg'));
                    });
                }
                return status;
            },
        },
    };

</script>
