<template>
    <y-panel
        class="comment__info"
        :title="$t('comment.single.info.title')"
    >
        <template slot="functions">
            <!-- status -->
            <span
                v-if="!showCombo && info.flag_title"
                class="label comment-item-change-status"
                @click="comboStatus"
            >
                <i class="icon md-pencil-box-outline" />
                {{ info.flag_title }}
            </span>
            <div
                v-if="showCombo && metadata && metadata.applicable_flags"
                class="comment-item-change-status-combo"
            >
                <y-form-field
                    v-model="model"
                    type="select"
                    class="mb0"
                    name="status"
                    no-label
                    :options="metadata.applicable_flags"
                />
                <y-button
                    class="form-btn green"
                    @click="changeStatus"
                >
                    {{ $t('comment.single.reply.confirmed') }}
                </y-button>
            </div>
        </template>

        <div
            v-if="info"
            class="row"
        >
            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.user_name') }}
                    </div>
                    <div
                        v-if="info.user.name"
                        class="value"
                    >
                        {{ info.user.name }}
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.created_at') }}
                    </div>
                    <div
                        v-if="info.created_at"
                        class="value"
                    >
                        {{ info.created_at | date('DD MMMM YYYY - HH:mm', $i18n.locale) }}
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="info"
            class="row"
        >
            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.user_email') }}
                    </div>
                    <div
                        v-if="info.user.email"
                        class="value"
                    >
                        <y-form-email-label
                            :content="info.user.email"
                            copy
                        />
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.user_mobile') }}
                    </div>
                    <div
                        v-if="info.user.mobile"
                        class="value"
                    >
                        {{ info.user.mobile | digits }}
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="info"
            class="row"
        >
            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.user_website') }}
                    </div>
                    <div
                        v-if="info.user.website"
                        class="value"
                    >
                        {{ info.user.website }}
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.user_ip') }}
                    </div>
                    <div
                        v-if="info.user.ip"
                        class="value"
                    >
                        {{ info.user.ip | digits }}
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="info"
            class="row"
        >
            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.related_post') }}
                    </div>
                    <div
                        v-if="info.post && info.post.title"
                        class="value"
                    >
                        <a
                            v-if="info.post.direct_link"
                            :href="info.post.direct_link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {{ info.post.title | digits }}
                        </a>
                        <span v-else>{{ info.post.title | digits }}</span>
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="title">
                        {{ $t('comment.single.info.related_type') }}
                    </div>
                    <div
                        v-if="info.post && info.post.type && info.post.type.title"
                        class="value"
                    >
                        <a
                            v-if="info.post.direct_link"
                            :href="info.post.direct_link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {{ info.post.type.title | digits }}
                        </a>
                        <span v-else>{{ info.post.type.title | digits }}</span>
                    </div>
                    <div
                        v-else
                        class="value"
                    >
                        {{ `-` }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!info">
            <y-empty-state
                :message="$t('comment.single.info.empty')"
                height="250"
                class="mb0"
            />
        </div>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';
    import YEmptyState from '@deps/EmptyState';
    import YFormEmailLabel from '@deps/form/elements/EmailLabel';

    export default {
        name: 'SingleInfo',

        components: {
            YFormEmailLabel,
            YFormField,
            YEmptyState,
        },

        props: {
            info    : [Object, Array],
            metadata: [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : null,
                showCombo: false,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.info) {
                this.$set(this, 'model', this.info.flag);
            }
        },

        methods: {
            /**
             * Show status combo
             */
            comboStatus() {
                this.showCombo = !this.showCombo;
            },
            /**
             * Change comment status
             */
            changeStatus() {
                let status = null;
                if (this.model === this.info.flag) {
                    this.showCombo = !this.showCombo;
                }
                const params = {
                    id  : this.info.id,
                    flag: this.model,
                };
                if (this.model !== this.info.flag) {
                    status = this.$services.Commenting.replyByAdmin(params).then(() => {
                        this.$toast.success(this.$t('comment.single.reply.change_status.success_msg'));
                        this.$emit('done');
                    }).catch((error) => {
                        this.handleError(error, this.$t('comment.single.reply.change_status.error_msg'));
                    });
                }
                return status;
            },
        },
    };

</script>
