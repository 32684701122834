<template>
    <y-panel
        class="comment__reply"
        :title="$t('comment.single.reply.title')"
    >
        <div>
            <form
                id="reply-form"
                @submit.prevent="submit(save)"
            >
                <y-form-field
                    v-model="model.text"
                    type="textarea"
                    class="input"
                    name="message"
                    :placeholder="$t('comment.single.reply.placeholder')"
                    no-label
                    no-resize
                    validation="required"
                />
            </form>
        </div>
        <template slot="footer">
            <div class="comment__reply__footer d-f justify-between align-items-center">
                <div class="d-f justify-start align-items-center">
                    <y-form-field
                        v-if="metadata && metadata.applicable_flags"
                        v-model="model.flag"
                        type="select"
                        class="mb0 comment__reply__footer__combo"
                        name="status"
                        :label="$t('comment.single.reply.status')"
                        :placeholder="$t('comment.single.reply.change_status.placeholder')"
                        :options="metadata.applicable_flags"
                    />
                    <y-form-field
                        id="is_private"
                        v-model="model._is_private"
                        type="checkbox"
                        class="mb0 mr50 mt25"
                        name="is_private"
                    />
                </div>
                <y-button
                    class="form-btn green"
                    form="reply-form"
                    type="submit"
                    loading-on="saving-comment"
                >
                    {{ $t('button.submit') }}
                </y-button>
            </div>
        </template>
    </y-panel>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YFormField } from '@deps';

    export default {
        name: 'SingleReply',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            metadata: [Object, Array],
            id      : String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$set(this.model, 'flag', 'approved');
        },

        methods: {

            /**
             * Do send comment
             */
            save() {
                const params = {
                    id: this.id ? this.id : this.$route.params.id,
                    ...this.model,
                };
                return this.$services.Commenting.replyByAdmin(params).then(() => {
                    this.model = {};
                    this.$toast.success(this.$t('comment.single.reply.success_msg'));
                    this.$wait.end('saving-comment');
                    this.$emit('done');
                }).catch((error) => {
                    this.$wait.end('saving-comment');
                    this.handleError(error, this.$t('comment.single.reply.error_msg'));
                });
            },
        },
    };

</script>
